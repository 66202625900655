import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit {
  loginEmail: string;
  loginPassword: string;
  emailControl = new FormControl("", [Validators.required, Validators.email]);
  hidePassword = true;

  constructor(public auth: AngularFireAuth, private router: Router) {}

  ngOnInit(): void {
    if (this.auth.currentUser !== null) {
      this.router.navigateByUrl("/home-page");
    }
  }

  getEmailErrorMessage() {
    return this.emailControl.hasError("required")
      ? "You must enter a value"
      : this.emailControl.hasError("email")
      ? "Not a valid email"
      : "";
  }

  async login() {
    await this.auth
      .signInWithEmailAndPassword(this.loginEmail, this.loginPassword)
      .then(() => {
        this.router.navigateByUrl("/home-page");
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  }

  // async logout() {
  //   await this.auth.signOut().catch((error) => {
  //     console.log(error);
  //     alert(error);
  //   });
  // }

  enterEmail(value: string) {
    this.loginEmail = value;
  }

  enterPassword(value: string) {
    this.loginPassword = value;
  }
}
