import { Injectable } from "@angular/core";

import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";

import { Grocery } from "src/app/models/grocery";
import { StoreName } from "src/app/models/store";

@Injectable({
  providedIn: "root",
})
export class GroceryService {
  constructor(public afs: AngularFirestore) {}

  async addItem(itemValue: string, collection: AngularFirestoreCollection) {
    const id = this.afs.createId();
    const groceryItem: Grocery = {
      value: itemValue,
      lineThrough: false,
      deleted: false,
      savedTime: Date.now(),
      id: id,
    };
    await collection
      .doc(id)
      .set(groceryItem)
      .catch((error) => {
        alert(error);
      });
  }

  getDeleted(storeName: StoreName) {
    const deletedLocal = localStorage.getItem(`${storeName}Deleted`);
    if (deletedLocal !== null) {
      return JSON.parse(deletedLocal);
    } else {
      return null;
    }
  }

  saveDeleted(groceryItem: Grocery, storeName: StoreName) {
    const localDeleted = this.getDeleted(storeName);
    let deletedItems = [];
    if (localDeleted !== null) {
      deletedItems = localDeleted;
    }
    if (deletedItems.length === 5) {
      deletedItems.shift();
    }

    deletedItems.push(groceryItem);
    localStorage.setItem(`${storeName}Deleted`, JSON.stringify(deletedItems));
  }

  async deleteItem(
    groceryItem: Grocery,
    collection: AngularFirestoreCollection,
    storeName: StoreName
  ) {
    groceryItem.deleted = true;
    this.saveDeleted(groceryItem, storeName);

    await collection
      .doc(groceryItem.id)
      .delete()
      .catch((error) => {
        throw error;
      });
  }

  async restoreItem(
    groceryItem: Grocery,
    collection: AngularFirestoreCollection,
    storeName: StoreName
  ) {
    try {
      await this.addItem(groceryItem.value, collection);
      const deletedItems = this.getDeleted(storeName);
      if (deletedItems !== null) {
        const deletedSave = deletedItems.filter(
          (deletedItem) => deletedItem.id != groceryItem.id
        );
        localStorage.setItem(
          `${storeName}Deleted`,
          JSON.stringify(deletedSave)
        );
      }
    } catch (error) {
      throw error;
    }
  }
}
