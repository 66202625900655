import { Component, OnInit } from "@angular/core";

import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
})
export class HomePageComponent implements OnInit {
  wegmans = false;
  costco = false;
  wegmansDeleted = false;
  costcoDeleted = false;

  constructor(private auth: AngularFireAuth, private router: Router) {
    this.wegmans = true;
  }

  ngOnInit(): void {}

  async logout() {
    await this.auth
      .signOut()
      .then(() => {
        this.router.navigateByUrl("/login-page");
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  }

  colorChange() {
    this.wegmans = !this.wegmans;
    this.costco = !this.costco;
  }

  showDeleteToggleWegmans() {
    this.wegmansDeleted = !this.wegmansDeleted;
  }

  showDeleteToggleCostco() {
    this.costcoDeleted = !this.costcoDeleted;
  }
}
