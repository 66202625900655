import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { Observable } from "rxjs";

import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";

import { Grocery } from "../models/grocery";
import { GroceryService } from "../services/grocery/grocery.service";
import { StoreName } from "../models/store";
import { StyleService } from "../services/style/style.service";

@Component({
  selector: "app-wegmans",
  templateUrl: "./wegmans.component.html",
  styleUrls: ["./wegmans.component.scss"],
})
export class WegmansComponent implements OnInit {
  createForm = new FormGroup({
    item: new FormControl(""),
  });
  wegmansCollection: AngularFirestoreCollection<Grocery>;
  wegmansItems: Observable<Grocery[]>;
  @Input()
  get showDeleted() {
    return this._showDeleted;
  }
  set showDeleted(showDeleted: boolean) {
    this._showDeleted = showDeleted;
    this.selectItems();
  }

  private _showDeleted = false;
  deletedItems = [];

  constructor(
    private afs: AngularFirestore,
    private groceryService: GroceryService,
    private styleService: StyleService
  ) {
    this.selectItems();
  }

  ngOnInit(): void {}

  selectItems() {
    this.wegmansCollection = this.afs.collection<Grocery>(
      "GroceryItems",
      (ref) => ref.orderBy("value")
    );
    this.wegmansItems = this.wegmansCollection.valueChanges();
    this.deletedItems = this.groceryService.getDeleted(StoreName.WEGMANS);
  }

  async addItem() {
    try {
      const value = this.createForm.controls.item.value;
      await this.groceryService.addItem(value, this.wegmansCollection);
      this.createForm.controls.item.reset();
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }

  async linethrough(wegmansItem: Grocery) {
    try {
      await this.styleService.linethrough(wegmansItem, this.wegmansCollection);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }

  getLineThrough(wegmansItem: Grocery) {
    try {
      return this.styleService.getLineThrough(wegmansItem);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }

  getColor(wegmansItem: Grocery) {
    return this.styleService.getColor(wegmansItem, StoreName.WEGMANS);
  }

  async restoreItem(wegmansItem: Grocery) {
    try {
      await this.groceryService.restoreItem(
        wegmansItem,
        this.wegmansCollection,
        StoreName.WEGMANS
      );
      this.deletedItems = this.groceryService.getDeleted(StoreName.WEGMANS);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }

  async deleteItem(wegmansItem: Grocery) {
    try {
      await this.groceryService.deleteItem(
        wegmansItem,
        this.wegmansCollection,
        StoreName.WEGMANS
      );
      this.deletedItems = this.groceryService.getDeleted(StoreName.WEGMANS);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }
}
