<section class="home-page">
  <mat-toolbar color="primary" *ngIf="wegmans">
    <mat-toolbar-row>
      <span>Evans Groceries</span>
      <span class="home-page__display-spacer"></span>
      <button>
        <span class="material-icons" (click)="showDeleteToggleWegmans()">
          restore_from_trash
        </span>
      </button>
      <span class="home-page__display-spacer"></span>
      <button (click)="logout()">
        <mat-icon class="display-icon">input</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-toolbar color="accent" *ngIf="costco">
    <mat-toolbar-row>
      <span>Evans Groceries</span>
      <span class="home-page__display-spacer"></span>
      <button>
        <span class="material-icons" (click)="showDeleteToggleCostco()">
          restore_from_trash
        </span>
      </button>
      <span class="home-page__display-spacer"></span>
      <button (click)="logout()">
        <mat-icon class="display-icon">input</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-tab-group mat-align-tabs="center" (selectedTabChange)="colorChange()">
    <mat-tab label="Wegmans" click="showWegmans()">
      <app-wegmans [showDeleted]="wegmansDeleted"></app-wegmans>
    </mat-tab>
    <mat-tab label="Costco" click="showCostco()">
      <app-costco [showDeleted]="costcoDeleted"></app-costco>
    </mat-tab>
  </mat-tab-group>
</section>
