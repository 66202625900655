import { Injectable } from "@angular/core";

import { AngularFirestoreCollection } from "@angular/fire/firestore";

import { Grocery } from "src/app/models/grocery";
import { StoreName } from "src/app/models/store";

@Injectable({
  providedIn: "root",
})
export class StyleService {
  constructor() {}

  async linethrough(
    groceryItem: Grocery,
    collection: AngularFirestoreCollection
  ) {
    if (groceryItem.lineThrough) {
      groceryItem.lineThrough = false;
    } else {
      groceryItem.lineThrough = true;
    }
    await collection
      .doc(groceryItem.id)
      .set(groceryItem)
      .catch((error) => {
        alert(error);
      });
  }

  getLineThrough(groceryItem: Grocery) {
    if (groceryItem.lineThrough) {
      return "line-through";
    } else {
      return "none";
    }
  }

  getColor(groceryItem: Grocery, storeName: StoreName) {
    if (storeName === StoreName.WEGMANS) {
      if (groceryItem.deleted) {
        return "#FF0000";
      } else {
        return "#59330f";
      }
    } else if (storeName === StoreName.COSTCO) {
      if (groceryItem.deleted) {
        return "#FF0000";
      } else {
        return "#0060a9";
      }
    }
  }
}
