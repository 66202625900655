export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCU5yTS9KmACykjZE1mFjqIGxW4u9BdQJI',
    authDomain: 'evans-groceries-1c92c.firebaseapp.com',
    databaseURL: 'https://evans-groceries-1c92c.firebaseio.com',
    projectId: 'evans-groceries-1c92c',
    storageBucket: 'evans-groceries-1c92c.appspot.com',
    messagingSenderId: '',
  },
};
