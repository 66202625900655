<section class="costco">
  <form [formGroup]="createForm" (ngSubmit)="addItem()">
    <mat-form-field appearance="outline">
      <input matInput placeholder="Costco Item" formControlName="item" />
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit">Add Item</button>
  </form>
  <ol>
    <li *ngFor="let costcoItem of costcoItems | async">
      <span>
        <input
          type="checkbox"
          (click)="linethrough(costcoItem)"
          [checked]="costcoItem.lineThrough"
          class="costco__checkbox"
        />
        <div class="costco__value" [ngStyle]="{ color: getColor(costcoItem) }">
          <label
            [ngStyle]="{ 'text-decoration': getLineThrough(costcoItem) }"
            >{{ costcoItem.value }}</label
          >
        </div>
        <button (click)="deleteItem(costcoItem)">
          <mat-icon color="warn">remove_shopping_cart</mat-icon>
        </button>
      </span>
    </li>
  </ol>
  <ol *ngIf="deletedItems !== null && showDeleted === true">
    <li *ngFor="let deletedItem of deletedItems">
      <span>
        <input
          type="checkbox"
          (click)="linethrough(deletedItem)"
          [checked]="deletedItem.lineThrough"
          class="costco__checkbox"
        />
        <div class="costco__value" [ngStyle]="{ color: getColor(deletedItem) }">
          <label
            [ngStyle]="{ 'text-decoration': getLineThrough(deletedItem) }"
            >{{ deletedItem.value }}</label
          >
        </div>
        <button (click)="restoreItem(deletedItem)" *ngIf="deletedItem.deleted">
          <mat-icon class="costco__restore">restore</mat-icon>
        </button>
      </span>
    </li>
  </ol>
</section>
