<section class="login">
  <form class="login__form">
    <h1>Evans Groceries</h1>
    <mat-form-field appearance="outline">
      <input
        #inputEmail
        matInput
        placeholder="Enter your email"
        [formControl]="emailControl"
        required
        (keyup)="enterEmail(inputEmail.value)"
      />
      <mat-error *ngIf="emailControl.invalid">{{
        getEmailErrorMessage()
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input
        #inputPassword
        matInput
        placeholder="Enter your password"
        [type]="hidePassword ? 'password' : 'text'"
        (keyup)="enterPassword(inputPassword.value)"
      />
      <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
        hidePassword ? "visibility_off" : "visibility"
      }}</mat-icon>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="login()">Login</button>
  </form>
</section>
