<section class="wegmans">
  <form [formGroup]="createForm" (ngSubmit)="addItem()">
    <mat-form-field appearance="outline">
      <input matInput placeholder="Wegmans Item" formControlName="item" />
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit">Add Item</button>
  </form>
  <ol>
    <li *ngFor="let wegmansItem of wegmansItems | async">
      <span>
        <input
          type="checkbox"
          (click)="linethrough(wegmansItem)"
          [checked]="wegmansItem.lineThrough"
          class="wegmans__checkbox"
        />
        <div
          class="wegmans__value"
          [ngStyle]="{ color: getColor(wegmansItem) }"
        >
          <label
            [ngStyle]="{ 'text-decoration': getLineThrough(wegmansItem) }"
            >{{ wegmansItem.value }}</label
          >
        </div>
        <button (click)="deleteItem(wegmansItem)" *ngIf="!wegmansItem.deleted">
          <mat-icon color="warn">remove_shopping_cart</mat-icon>
        </button>
        <button (click)="restoreItem(wegmansItem)" *ngIf="wegmansItem.deleted">
          <mat-icon class="wegmans__restore">restore</mat-icon>
        </button>
      </span>
    </li>
  </ol>
  <ol *ngIf="deletedItems !== null && showDeleted === true">
    <li *ngFor="let deletedItem of deletedItems">
      <span>
        <input
          type="checkbox"
          (click)="linethrough(deletedItem)"
          [checked]="deletedItem.lineThrough"
          class="wegmans__checkbox"
        />
        <div
          class="wegmans__value"
          [ngStyle]="{ color: getColor(deletedItem) }"
        >
          <label
            [ngStyle]="{ 'text-decoration': getLineThrough(deletedItem) }"
            >{{ deletedItem.value }}</label
          >
        </div>
        <button (click)="restoreItem(deletedItem)" *ngIf="deletedItem.deleted">
          <mat-icon class="wegmans__restore">restore</mat-icon>
        </button>
      </span>
    </li>
  </ol>
</section>
