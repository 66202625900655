import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';

import { HomePageComponent } from './home-page/home-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const redirectLoggedInToHomePage = () => redirectLoggedInTo(['home-page']);
const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['login-page']);

const routes: Routes = [
  {
    path: 'login-page',
    component: LoginPageComponent,
    data: { authGuardPipe: redirectLoggedInToHomePage },
  },
  {
    path: 'home-page',
    component: HomePageComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  { path: '', redirectTo: '/login-page', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
